<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-if="!loading">
          <crud-list
            v-model="extratoAssociados"
            :headers="headers"
            :actions="actions"
            :permission="'list_extrato_associado'"
            :sort-by="['razao_social']"
            :sort-desc="[false]"
            :slots="[
              'item.data_referencia',
              'item.status',
              'item.telefones',
              'item.valor_faturado',
              'item.valor_devolucao',
              'item.valor_liquido',
              'item.valor_saldo_inicial',
              'item.valor_credito',
              'item.valor_debito',
              'item.valor_saldo_final',
            ]"
            total-column
            :export-pdf-xlsx="true"
          >
            <template slot="datas">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <data-field
                  v-model="data_i"
                  :label="'Mês de Referência'"
                  :mes-ano="true"
                  @set="handleDataFilter()"
                />
              </v-col>
            </template>
            <template
              v-slot:[`item.status`]="{ item }"
            >
              <v-checkbox
                v-model="item.status"
                class="mt-0"
                color="success"
                :loading="false"
                hide-details
                disabled="true"
              />
            </template>
            <template v-slot:[`item.data_referencia`]="{ item }">
              <span>{{ moment( item.data_referencia ).format( "MM/YYYY") }}</span>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
            <template v-slot:[`item.valor_faturado`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_faturado || 0) }}</span>
            </template>
            <template v-slot:[`item.valor_devolucao`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_devolucao || 0) }}</span>
            </template>
            <template v-slot:[`item.valor_liquido`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_liquido || 0) }}</span>
            </template>
            <template v-slot:[`item.valor_saldo_inicial`]="{ item }">
              <span v-if="item.valor_saldo_inicial >= 0">
                <strong style="font-size: 14px; color: #006aff;font-weight: bold; width: auto; min-width: auto">R$ {{ currencyFormatter(item.valor_saldo_inicial) }}</strong>
              </span>
              <span v-else>
                <strong style="font-size: 14px; color: #fd0404;font-weight: bold; width: auto; min-width: auto">R$ {{ currencyFormatter(Math.abs(parseFloat(item.valor_saldo_inicial))) }}</strong>
              </span>
            </template>
            <template v-slot:[`item.valor_credito`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_credito) }}</span>
            </template>
            <template v-slot:[`item.valor_debito`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_debito) }}</span>
            </template>
            <template v-slot:[`item.valor_saldo_final`]="{ item }">
              <span v-if="item.valor_saldo_final >= 0">
                <strong style="font-size: 14px; color: #006aff;font-weight: bold; width: auto; min-width: auto">R$ {{ currencyFormatter(item.valor_saldo_final) }}</strong>
              </span>
              <span v-else>
                <strong style="font-size: 14px; color: #fd0404;font-weight: bold; width: auto; min-width: auto">R$ {{ currencyFormatter( Math.abs(parseFloat(item.valor_saldo_final)) ) }}</strong>
              </span>
            </template>
          </crud-list>
          <extrato-dialog
            :dados-dialog="dadosItem"
            @extrato-sintetico="extratoSintetico"
            @extrato-analitico="extratoAnalitico"
          />
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import ExtratoAssociadoStore, { BOOTSTRAP, PERIODO, OPEN } from '@/store/modules/extratoAssociados'
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import { sortDateTime } from '../../utils/sorts'
  import { currencyFormatter } from '../../utils/formatter'
  import ExtratoDialog from './ExtratoDialog.vue'
  import moment from 'moment'
  import jsPDF from 'jspdf'
  export default {
    components: { ExtratoDialog },
    data () {
      return {
        currencyFormatter,
        dadosItem: {},
        data_i: 'from',
        actions: [
          {
            title: 'Visualizar extrato do associado',
            color: 'info darken-3',
            click: item => this.openDialog(item),
            icon: 'mdi-eye-settings',
            permission: 'editar_regional',
          },
          {
            title: 'Imprimir Extrato Sintetico',
            color: 'info darken-3',
            click: item => this.extratoSintetico(item),
            icon: 'mdi-file',
            permission: 'imprimir_extrato_associado',
          },
          {
            title: 'Imprimir Extrato Analitico',
            color: 'info darken-3',
            click: item => this.extratoAnalitico(item),
            icon: 'mdi-file-search',
            permission: 'imprimir_extrato_associado',
          },
        ],
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '20' },
          { align: 'center', sortable: true, groupable: false, text: 'Referência', value: 'data_referencia', width: '10px', sort: sortDateTime, report: true, styleReport: { dateReference: true, titulo: 'Ref.' } },
          { align: 'center', sortable: false, groupable: false, text: 'Código', value: 'codigo', width: '40px', floatingfilter: true, report: true },
          { align: 'center', sortable: false, groupable: false, text: 'CNPJ', value: 'cnpj', width: '40px', floatingfilter: true },
          { align: 'left', sortable: false, groupable: false, text: 'Razão Social', value: 'razao_social', width: '40px', floatingfilter: true, report: true, styleReport: { cellWidth: 70 } },
          { align: 'left', sortable: false, groupable: false, text: 'Telefones', value: 'telefones', width: 'auto' },
          { align: 'right', sortable: true, groupable: false, text: 'Total Faturado', value: 'valor_faturado', width: 'auto', floatingfilter: false, report: true, sum: 'Money', styleReport: { decimal: 'money', halign: 'right', sum: 'Money' } },
          { align: 'right', sortable: true, groupable: false, text: 'Total Devolvido', value: 'valor_devolucao', width: 'auto', floatingfilter: false, report: true, sum: 'Money', styleReport: { decimal: 'money', halign: 'right', sum: 'Money' } },
          { align: 'right', sortable: true, groupable: false, text: 'Total Líquido', value: 'valor_liquido', width: 'auto', floatingfilter: false, sum: 'Money', styleReport: { decimal: 'money', halign: 'right', sum: 'Money' } },
          { align: 'right', sortable: true, groupable: false, text: 'Saldo Inicial', value: 'valor_saldo_inicial', width: 'auto', floatingfilter: false, report: true, sum: 'Money', styleReport: { decimal: 'money', halign: 'right', sum: 'money' } },
          { align: 'right', sortable: true, groupable: false, text: 'Créditos', value: 'valor_credito', width: 'auto', floatingfilter: false, report: true, sum: 'Money', styleReport: { decimal: 'money', halign: 'right', sum: 'money' } },
          { align: 'right', sortable: true, groupable: false, text: 'Débitos', value: 'valor_debito', width: 'auto', floatingfilter: false, report: true, sum: 'Money', styleReport: { decimal: 'money', halign: 'right', sum: 'money' } },
          { align: 'right', sortable: true, groupable: false, text: 'Saldo Final', value: 'valor_saldo_final', width: 'auto', floatingfilter: false, report: true, sum: 'Money', styleReport: { decimal: 'money', halign: 'right', sum: 'money' } },
        ],
      }
    },
    computed: {
      ...mapState('extratoAssociados', ['loading', 'extratoAssociados', 'dialog']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.hasModule('extratoAssociados')) { this.$store.registerModule('extratoAssociados', ExtratoAssociadoStore) }

      let data_i = new Date()

      data_i = new Date(data_i.getFullYear(), data_i.getMonth(), 1)
      data_i = data_i.toISOString().slice(0, 10)

      this.data_i = data_i

      this.PERIODO({ data_i })

      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('extratoAssociados', [BOOTSTRAP, OPEN]),
      ...mapMutations('extratoAssociados', [PERIODO]),
      handleDataFilter () {
        const data_i = new Date(this.data_i.substring(0, 4) + '/' + this.data_i.substring(5, 7) + '/01')
        data_i.setTime(data_i.getTime() + data_i.getTimezoneOffset() * 60 * 1000)
        this.PERIODO({ data_i })
        this.BOOTSTRAP()
      },
      openDialog (item) {
        this.dadosItem = item
        this.OPEN()
      },
      extratoSintetico (dados) {
        var colorTable = [255, 255, 255]
        var relatorio = new jsPDF()
        function addHeader () {
          relatorio.addImage(window.location.origin + '/img/logo_branca.png', 'png', margin.left, 5, 20, 20)
          relatorio.setFontSize(15)
          relatorio.text('Extrato Sintetico Associado', docWidth / 2, 15, { align: 'center' })
          relatorio.setFontSize(10)
          relatorio.text('Data: ' + moment(new Date()).format('DD/MM/YYYY'), 175, 17)
          relatorio.text('Hora: ' + moment(new Date()).format('hh:mm:ss'), 175, 25)
        }
        function addFooter (pageNumber) {
          relatorio.setFontSize(9)
          relatorio.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - margin.bottom, { align: 'right' })
        }
        function topoTabela () {
          relatorio.setFillColor(10, 10, 10)
          relatorio.line(5, 30, pageWidth - 5, 30)
          relatorio.line(5, 30, 5, 50)
          relatorio.line(pageWidth - 5, 30, pageWidth - 5, 50)
          relatorio.setFontSize(8)
          relatorio.text('Associado', 10.7, 34)
          relatorio.setFontSize(9)
          relatorio.text(dados.razao_social, 10, 38)
          relatorio.setFontSize(8)
          relatorio.text('CNPJ', 10.7, 43)
          relatorio.setFontSize(9)
          relatorio.text(dados.cnpj, 10, 47)
          relatorio.setFontSize(8)
          relatorio.text('Telefone', 95.7, 43)
          relatorio.setFontSize(9)
          relatorio.text(dados.telefones[0], 95, 47)
          relatorio.setFontSize(8)
          relatorio.text('Período', 170.7, 43)
          relatorio.setFontSize(9)
          relatorio.text(moment(dados.data_referencia).format('MM/YYYY'), 170, 47)

          addHeader()
        }
        function bacgroundTable (troca) {
          if (troca) {
            colorTable = JSON.stringify(colorTable) === JSON.stringify([245, 245, 245]) ? [255, 255, 255] : [245, 245, 245]
          }
          return colorTable
        }
        function formatarDialog () {
          var dadoFormat = []
          if (dados.resumo_eventos) {
            dados.resumo_eventos.forEach((item) => {
              const row = []
              row.unshift({
                rowSpan: item.complemento.length + 1,
                content: item.codigo,
                styles: {
                  valign: 'middle',
                  halign: 'center',
                  lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                  cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 },
                  fillColor: bacgroundTable(true),
                  fontSize: 8,
                  fontStyle: 'bold',
                },
              })
              row.push({
                content: item.descricao_evento,
                styles: {
                  lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                  cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 },
                  fillColor: bacgroundTable(),
                  fontSize: 8,
                  fontStyle: 'bold',
                },
              })
              row.push({
                rowSpan: item.complemento.length + 1,
                content: item.tipo_evento === 0 ? 'R$ ' + currencyFormatter(item.valor_calculo) : '',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                  lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                  cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 },
                  fillColor: bacgroundTable(),
                  fontSize: 8,
                  fontStyle: 'bold',
                },
              })
              row.push({
                rowSpan: item.complemento.length + 1,
                content: item.tipo_evento === 1 ? 'R$ ' + currencyFormatter(item.valor_calculo) : '',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                  lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                  cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 },
                  fillColor: bacgroundTable(),
                  fontSize: 8,
                  fontStyle: 'bold',
                },
              })
              dadoFormat.push(row)
              if (item.complemento.length > 0) {
                for (let i = 0; i < item.complemento.length; i++) {
                  dadoFormat.push([{
                    content: item.complemento[i],
                    styles: {
                      fontSize: 8,
                      lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                      cellPadding: { top: 0.5, bottom: 0.5, left: 4, right: 1.5 },
                      fillColor: bacgroundTable(),
                    },
                  }])
                }
              }
            })
            return dadoFormat
          }
          return []
        }
        function labelSaldoFinal () {
          if (parseFloat(dados.valor_saldo_final) < 0) {
            return 'Saldo a Pagar'
          } else {
            return 'Saldo a Receber'
          }
        }
        var totalPages = '{total_pages_count_string}'
        const stylesBorder = { lineWidth: 0.2, lineColor: 10 }
        const docWidth = relatorio.internal.pageSize.width
        const docHeight = relatorio.internal.pageSize.height
        var pageSize = relatorio.internal.pageSize
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
        const margin = { left: 10, right: 10, bottom: 10 }

        relatorio.autoTable({
          theme: 'striped',
          styles: stylesBorder,
          headStyles: { textColor: 0, lineWidth: 0.2, fontSize: 8, cellPadding: 1, fillColor: [255, 255, 255] },
          bodyStyles: { textColor: 0 },
          head: [['Código', 'Descrição', 'Crédito', 'Débito']],
          body: formatarDialog(),
          columnStyles: { 0: { cellWidth: 17 }, 2: { cellWidth: 30 }, 3: { cellWidth: 30 } },
          startY: 50,
          pageBreak: 'auto',
          margin: { left: 5, top: 50, bottom: (margin.bottom + 5), right: 5 },
          didParseCell: (data) => {
            if (data.row.section === 'body' && data.row.index === 0) {
              data.cell.styles.lineWidth = { top: 0.2, bottom: 0, left: 0.2, right: 0.2 }
              data.cell.styles.cellPadding = { top: 1.5, bottom: 0.5, left: 1.5, right: 1.5 }
            } else if (data.row.section === 'body' && data.row.index === data.table.body.length - 1) {
              data.cell.styles.cellPadding.bottom = 1
            }
            if (data.row.section === 'body' && (data.column.dataKey === 0 || data.column.dataKey === 2 || data.column.dataKey === 3)) {
              data.cell.styles.halign = 'right'
            }
          },
          willDrawPage: (data) => {
            topoTabela()
          },
          didDrawPage: (data) => {
            addFooter(data.pageNumber)
          },
        })
        const finalYprincipal = relatorio.lastAutoTable.finalY
        relatorio.line(5, relatorio.lastAutoTable.finalY, pageWidth - 5, relatorio.lastAutoTable.finalY)
        relatorio.autoTable({
          theme: 'plain',
          styles: { halign: 'center', lineWidth: { left: 0.2, right: 0.2 }, lineColor: 10, minCellWidth: 50 },
          headStyles: { fontSize: 8, cellPadding: { top: 1, bottom: 0.5, left: 1, right: 1 } },
          bodyStyles: { fontSize: 10, cellPadding: { top: 0.5, bottom: 1, left: 1, right: 1 } },
          columnStyles: { valor_saldo_final: { fontStyle: 'bold' } },
          margin: { left: 5, top: 50, right: 5 },
          columns: [
            { dataKey: 'valor_saldo_inicial', header: 'Saldo Anterior' },
            { dataKey: 'valor_credito', header: 'Total Crédito' },
            { dataKey: 'valor_debito', header: 'Total Débito' },
            { dataKey: 'valor_saldo_final', header: labelSaldoFinal() },
          ],

          body: [
            {
              valor_saldo_inicial: 'R$ ' + currencyFormatter(dados.valor_saldo_inicial),
              valor_credito: 'R$ ' + currencyFormatter(dados.valor_credito),
              valor_debito: 'R$ ' + currencyFormatter(dados.valor_debito),
              valor_saldo_final: 'R$ ' + currencyFormatter(Math.abs(parseFloat(dados.valor_saldo_final))),
            },
          ],
          startY: relatorio.lastAutoTable.finalY,
        })
        relatorio.line(5, relatorio.lastAutoTable.finalY, pageWidth - 5, relatorio.lastAutoTable.finalY)
        relatorio.line(5, finalYprincipal, 5, relatorio.lastAutoTable.finalY)
        relatorio.line(pageWidth - 5, finalYprincipal, pageWidth - 5, relatorio.lastAutoTable.finalY)
        if (typeof relatorio.putTotalPages === 'function') {
          relatorio.putTotalPages(totalPages)
        }

        relatorio.save(`relatorio-sintetico-${moment().format('DD-MM-YYYY-HH_mm')}.pdf`)
      },

      extratoAnalitico (dados) {
        var colorTable = [255, 255, 255]
        const dadosTabela = []

        var relatorio = new jsPDF()
        function addHeader () {
          relatorio.addImage(window.location.origin + '/img/logo_branca.png', 'png', margin.left, 5, 20, 20)
          relatorio.setFontSize(15)
          relatorio.text('Extrato Analitico Associado', docWidth / 2, 15, { align: 'center' })
          relatorio.setFontSize(10)
          relatorio.text('Data: ' + moment(new Date()).format('DD/MM/YYYY'), 175, 17)
          relatorio.text('Hora: ' + moment(new Date()).format('hh:mm:ss'), 175, 25)
        }
        function addFooter (pageNumber) {
          relatorio.setFontSize(9)
          relatorio.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - margin.bottom, { align: 'right' })
        }
        function topoTabela () {
          relatorio.setFillColor(10, 10, 10)
          relatorio.line(5, 30, pageWidth - 5, 30)
          relatorio.line(5, 30, 5, 50)
          relatorio.line(pageWidth - 5, 30, pageWidth - 5, 50)
          relatorio.setFontSize(8)
          relatorio.text('Associado', 10.7, 34)
          relatorio.setFontSize(9)
          relatorio.text(dados.razao_social, 10, 38)
          relatorio.setFontSize(8)
          relatorio.text('CNPJ', 10.7, 43)
          relatorio.setFontSize(9)
          relatorio.text(dados.cnpj, 10, 47)
          relatorio.setFontSize(8)
          relatorio.text('Telefone', 95.7, 43)
          relatorio.setFontSize(9)
          relatorio.text(dados.telefones[0], 95, 47)
          relatorio.setFontSize(8)
          relatorio.text('Período', 170.7, 43)
          relatorio.setFontSize(9)
          relatorio.text(moment(dados.data_referencia).format('MM/YYYY'), 170, 47)

          addHeader()
        }
        function bacgroundTable (troca) {
          if (troca) {
            colorTable = JSON.stringify(colorTable) === JSON.stringify([245, 245, 245]) ? [255, 255, 255] : [245, 245, 245]
          }
          return colorTable
        }

        function criarEstruturaTabela () {
          dados.resumo_eventos.forEach((item) => {
            const dadosItens = dados.analitico_itens.filter((item2) => item.codigo === item2.evento.codigo)
            dadosTabela.push([
              {
                content: item.codigo,
                rowSpan: dadosItens.length === 1 ? 1 : dadosItens.length + 1,
                styles: {
                  valign: 'middle',
                  halign: 'center',
                  lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                  cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 },
                  fillColor: bacgroundTable(true),
                  fontSize: 8,
                  fontStyle: 'bold',
                },
              },
              {
                content: item.descricao_evento,
                styles: {
                  lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                  cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 },
                  fillColor: bacgroundTable(),
                  fontSize: 8,
                  fontStyle: 'bold',
                },
              },
              {
                content: item.tipo_evento === 0 ? 'R$ ' + currencyFormatter(item.valor_calculo) : '',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                  lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                  cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 },
                  fillColor: bacgroundTable(),
                  fontSize: 8,
                  fontStyle: 'bold',
                },
              },
              {
                content: item.tipo_evento === 1 ? 'R$ ' + currencyFormatter(item.valor_calculo) : '',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                  lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                  cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 },
                  fillColor: bacgroundTable(),
                  fontSize: 8,
                  fontStyle: 'bold',
                },
              },
            ])

            if (dadosItens.length > 1) {
              dadosItens.forEach((item2) => {
                dadosTabela.push([
                  {
                    content: item2.item_lancamento_avulso_id !== null ? item2.item_lancamento_avulso.lancamento_avulso.descricao : item2.fornecedor !== null ? item2.fornecedor.pessoajuridica.razao_social : item2.associado.pessoajuridica.razao_social,
                    styles: { fontSize: 8, lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 }, cellPadding: { top: 0.5, bottom: 0.5, left: 4, right: 1.5 }, fillColor: bacgroundTable() },
                  },
                  {
                    content: item2.evento.tipo_evento === 0 ? 'R$ ' + currencyFormatter(item2.valor_calculo) : '',
                    styles: {
                      fontSize: 8,
                      lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                      cellPadding: { top: 0.5, bottom: 0.5, left: 4, right: 1.5 },
                      fillColor: bacgroundTable(),
                    },
                  },
                  {
                    content: item2.evento.tipo_evento === 1 ? 'R$ ' + currencyFormatter(item2.valor_calculo) : '',
                    styles: {
                      fontSize: 8,
                      lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 },
                      cellPadding: { top: 0.5, bottom: 0.5, left: 4, right: 1.5 },
                      fillColor: bacgroundTable(),
                    },
                  },
                ])
              })
            }
          })
          return dadosTabela
        }
        function labelSaldoFinal () {
          if (parseFloat(dados.valor_saldo_final) < 0) {
            return 'Saldo a Pagar'
          } else {
            return 'Saldo a Receber'
          }
        }
        var totalPages = '{total_pages_count_string}'
        const stylesBorder = { lineWidth: 0.2, lineColor: 10 }
        const docWidth = relatorio.internal.pageSize.width
        const docHeight = relatorio.internal.pageSize.height
        var pageSize = relatorio.internal.pageSize
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
        const margin = { left: 10, right: 10, bottom: 10 }

        relatorio.autoTable({
          theme: 'striped',
          styles: stylesBorder,
          headStyles: { textColor: 0, lineWidth: 0.2, fontSize: 8, cellPadding: 1, fillColor: [255, 255, 255] },
          bodyStyles: { textColor: 0 },
          head: [['Código', 'Descrição', 'Crédito', 'Débito']],
          body: criarEstruturaTabela(),
          columnStyles: { 0: { cellWidth: 17 }, 2: { cellWidth: 30 }, 3: { cellWidth: 30 } },
          startY: 50,
          pageBreak: 'auto',
          margin: { left: 5, top: 50, bottom: (margin.bottom + 5), right: 5 },
          didParseCell: (data) => {
            if (data.row.section === 'body' && data.row.index === 0) {
              data.cell.styles.lineWidth = { top: 0.2, bottom: 0, left: 0.2, right: 0.2 }
              data.cell.styles.cellPadding = { top: 1.5, bottom: 0.5, left: 1.5, right: 1.5 }
            } else if (data.row.section === 'body' && data.row.index === data.table.body.length - 1) {
              data.cell.styles.cellPadding.bottom = 1
            }
            if (data.row.section === 'body' && (data.column.dataKey === 0 || data.column.dataKey === 2 || data.column.dataKey === 3)) {
              data.cell.styles.halign = 'right'
            }
          },
          willDrawPage: (data) => {
            topoTabela()
          },
          didDrawPage: (data) => {
            addFooter(data.pageNumber)
          },
        })
        const finalYprincipal = relatorio.lastAutoTable.finalY
        relatorio.line(5, relatorio.lastAutoTable.finalY, pageWidth - 5, relatorio.lastAutoTable.finalY)
        relatorio.autoTable({
          theme: 'plain',
          styles: { halign: 'center', lineWidth: { left: 0.2, right: 0.2 }, lineColor: 10, minCellWidth: 50 },
          headStyles: { fontSize: 8, cellPadding: { top: 1, bottom: 0.5, left: 1, right: 1 } },
          bodyStyles: { fontSize: 10, cellPadding: { top: 0.5, bottom: 1, left: 1, right: 1 } },
          columnStyles: { valor_saldo_final: { fontStyle: 'bold' } },
          margin: { left: 5, top: 50, right: 5 },
          columns: [
            { dataKey: 'valor_saldo_inicial', header: 'Saldo Anterior' },
            { dataKey: 'valor_credito', header: 'Total Crédito' },
            { dataKey: 'valor_debito', header: 'Total Débito' },
            { dataKey: 'valor_saldo_final', header: labelSaldoFinal() },
          ],

          body: [
            {
              valor_saldo_inicial: 'R$ ' + currencyFormatter(dados.valor_saldo_inicial),
              valor_credito: 'R$ ' + currencyFormatter(dados.valor_credito),
              valor_debito: 'R$ ' + currencyFormatter(dados.valor_debito),
              valor_saldo_final: 'R$ ' + currencyFormatter(Math.abs(parseFloat(dados.valor_saldo_final))),
            },
          ],
          startY: relatorio.lastAutoTable.finalY,

        })
        relatorio.line(5, relatorio.lastAutoTable.finalY, pageWidth - 5, relatorio.lastAutoTable.finalY)
        relatorio.line(5, finalYprincipal, 5, relatorio.lastAutoTable.finalY)
        relatorio.line(pageWidth - 5, finalYprincipal, pageWidth - 5, relatorio.lastAutoTable.finalY)
        if (typeof relatorio.putTotalPages === 'function') {
          relatorio.putTotalPages(totalPages)
        }

        relatorio.save(`relatorio-analitico-${moment().format('DD-MM-YYYY-HH_mm')}.pdf`)
      },
    },
  }
</script>
