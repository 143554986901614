<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    width="1200px"
    scrollable
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card min-height="570">
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span
            class="headline"
          >{{ 'Extrato Associado ' + moment(dadosDialog.data_referencia).format('MMMM/YY') }}</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card
        outlined
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="dadosDialog.razao_social"
                label="Razão Social"
                outlined
                rounded
                dense
                readonly
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="dadosDialog.telefones"
                label="Telefone"
                outlined
                rounded
                dense
                readonly
              />
            </v-col>
          </v-row>
          <v-card
            outlined
            style="border-radius: 20px;"
          >
            <v-row class="ma-1">
              <v-col
                cols="12"
                md="9"
              >
                <v-card
                  outlined
                  height="315"
                >
                  <v-simple-table style="height: 300px; overflow: auto;">
                    <thead>
                      <tr>
                        <th class="text-left pr-2">
                          Código
                        </th>
                        <th class="text-left px-2">
                          Descrição
                        </th>
                        <th class="text-right px-2">
                          Crédito
                        </th>
                        <th class="text-right pl-2">
                          Débito
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in dadosDialog.resumo_eventos"
                        :key="index"
                      >
                        <td
                          class="text-right pr-2"
                          width="20"
                          style="font-weight: 500;"
                        >
                          {{ item.codigo }}
                        </td>
                        <td class="text-left px-2">
                          <span style="font-weight: 500;">{{ item.descricao_evento }}</span>
                          <div v-if="item.complemento.length > 0">
                            <p
                              v-for="(itemComp, indexComp) in item.complemento"
                              :key="indexComp"
                              class="pl-6 my-0"
                              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                            >
                              {{ item.complemento[indexComp] }}
                            </p>
                          </div>
                        </td>
                        <td
                          class="text-right px-2"
                          width="120"
                          style="font-weight: 500;"
                        >
                          <span v-if="item.tipo_evento === 0">
                            {{ "R$ " + currencyFormatter(item.valor_calculo) }}
                          </span>
                        </td>
                        <td
                          class="text-right pl-2"
                          width="120"
                          style="font-weight: 500;"
                        >
                          <span v-if="item.tipo_evento === 1">
                            {{ "R$ " + currencyFormatter(item.valor_calculo) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <vuetify-money
                      :value="dadosAssociado.valor_saldo_inicial"
                      label="Saldo Anterior"
                      :options="money"
                      class="text-direita"
                      outlined
                      rounded
                      readonly
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <vuetify-money
                      :value="dadosAssociado.valor_credito"
                      label="Total Crédito"
                      :options="money"
                      class="text-direita"
                      outlined
                      rounded
                      readonly
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <vuetify-money
                      :value="dadosAssociado.valor_debito"
                      label="Total Débito"
                      :options="money"
                      class="text-direita"
                      outlined
                      rounded
                      readonly
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <vuetify-money
                      :value="Math.abs(parseFloat(dadosDialog.valor_saldo_final))"
                      :label="labelSaldoFinal() === 'P'? 'Saldo a Pagar' : 'Saldo a Receber'"
                      :options="money"
                      :class="labelSaldoFinal() === 'P' ? 'text-direita-red' : 'text-direita-blue'"
                      style="font-weight: 600;"
                      readonly
                      outlined
                      rounded
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card-actions
        class="justify-end mt-0"
      >
        <v-btn
          class="text-none text-white"
          color="error"
          rounded
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Fechar
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="primary"
          depressed
          rounded
          @click="extratoSintetico"
        >
          <v-icon
            dark
            left
          >
            mdi-file
          </v-icon>
          Sintetico
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="primary"
          depressed
          rounded
          @click="extratoAnalitico"
        >
          <v-icon
            dark
            left
          >
            mdi-file-search
          </v-icon>
          Analítico
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import rules from '../../utils/formRules'
  import { CLOSE } from '@/store/modules/extratoAssociados'
  import { mapState, mapMutations } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import jsPDF from 'jspdf'
  import VuetifyMoney from '../../components/base/VuetifyMoney.vue'
  import moment from 'moment'

  export default {
    name: 'ExtratoDialog',
    components: { VuetifyMoney },
    props: {
      dadosDialog: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        rules,
        dadosAssociado: [],
        currencyFormatter,
        valid: false,
        prefixo: 'R$',
        snackbar: { message: '', show: false, color: 'error' },
        headers: [
          { align: 'right', sortable: true, groupable: false, text: 'Codigo', value: 'codigo' },
          { align: 'left', text: 'Descrição', value: 'descricao_evento' },
          { align: 'left', text: 'Crédito', value: 'valor_credito' },
          { align: 'left', text: 'Débito', value: 'valor_debito' },
        ],

        money: {
          locale: 'pt-BR',
          precision: 2,
          prefix: 'R$',
        },
      }
    },
    computed: {
      ...mapState('extratoAssociados', ['loading', 'dialog']),
    },
    updated () {
      this.dadosAssociado = this.dadosDialog
    },
    methods: {
      ...mapMutations('extratoAssociados', [CLOSE]),
      cancel () {
        this.CLOSE()
      },
      labelSaldoFinal () {
        if (parseFloat(this.dadosDialog.valor_saldo_final) < 0) {
          return 'P'
        } else {
          return 'R'
        }
      },
      extratoAnalitico () {
        this.$emit('extrato-analitico', this.dadosDialog)
      },
      extratoSintetico () {
        this.$emit('extrato-sintetico', this.dadosDialog)
      },
    },
  }
</script>
<style lang="css">
.text-field-table.v-text-field--outline input {
    text-align: right !important;
}
.text-direita input {
  text-align: right;
}

.text-direita-blue input {
  text-align: right;
  color: #0800ff !important;
}

.text-direita-red input {
  text-align: right;
  color: #ff0000 !important;
}

.centered-input input {
      text-align: right;
      font-weight: bold;
    }
</style>
