export const sortCurrency = (a, b) => {
  try {
    const numberPattern = /\d+/g
    const c = (a.match(numberPattern) || []).join('')
    const d = (b.match(numberPattern) || []).join('')
    return c - d
  } catch {
    return 0
  }
}

export const sortDateTime = (a, b) => {
  try {
    // 31/01/2000 18:55:22
    // 2000/01/31 18:55:22
    const sepBar = (a.indexOf('/') > 0)
    const dateA = sepBar ? String(a).substring(0, 10).split('/') : String(a).substring(0, 10).split('-')
    const timeA = String(a).substring(11, 19).split(':')
    const dateB = sepBar ? String(b).substring(0, 10).split('/') : String(b).substring(0, 10).split('-')
    const timeB = String(b).substring(11, 19).split(':')
    const dateTimeA = new Date(
      parseInt(dateA[sepBar ? 2 : 0]),
      parseInt(dateA[1]),
      parseInt(dateA[sepBar ? 0 : 2]),
      timeA[0] ? parseInt(timeA[0]) : 0,
      timeA[1] ? parseInt(timeA[1]) : 0,
      timeA[2] ? parseInt(timeA[2]) : 0)
    const dateTimeB = new Date(
      parseInt(dateB[sepBar ? 2 : 0]),
      parseInt(dateB[1]),
      parseInt(dateB[sepBar ? 0 : 2]),
      timeB[0] ? parseInt(timeB[0]) : 0,
      timeB[1] ? parseInt(timeB[1]) : 0,
      timeB[2] ? parseInt(timeB[2]) : 0)
    return dateTimeA - dateTimeB
  } catch (e) {
    console.log(e)
    return 0
  }
}
